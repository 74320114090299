<template>
    <div class="page-container">
        <p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>   
          <el-form ref='form' prop="" label-width='200px' style="margin-left:10px;">
            <el-tabs v-model="active">
                <el-tab-pane label="域名配置" >
                            <div v-for="(item,index) in list" :key="index">
                                <el-form-item  v-if="item.type < 1 && item.group_name !=='chat-toolbar' && item.group_name !== 'activity_url'"  :label='item.remark'>
                                    <el-input v-model="item.value" placeholder="" :disabled='roleId == 1?false:true'></el-input>
                                </el-form-item> 
                            </div>
                </el-tab-pane>
				<el-tab-pane label="接收事件配置">
						<div v-for="(item,index) in callback_config" v-if="item.type < 4">
							<label style="font-size:14px;line-height:45px;">
								{{item.type_text}}
								<i @click="question(item.type)" class="el-icon-question" style="color: #909399;"></i>
							</label>
							<el-form-item   label="URL">
								<el-input   v-model="item.URL" placeholder="" :disabled='roleId == 1?false:true'></el-input>
							</el-form-item> 
							<el-form-item   label="Token">
								<el-input v-model="item.Token" placeholder=""></el-input>
							</el-form-item>
							<el-form-item   label="EncodingAESKey">
								<el-input v-model="item.EncodingAESKey" placeholder=""></el-input>
							</el-form-item>	
						</div>
				</el-tab-pane>
            </el-tabs>
        </el-form>
         <div style="line-height:100px;margin-left:20px;">
                <el-button type="primary" @click="update()">更新</el-button>
        </div>
		<DocVue ref="docDialog"></DocVue>
    </div>
</template>
<script>
import DocVue  from './Doc.vue'	
export default {
    inject:['reload'],
	components:{
	    DocVue
	},
    data() {
        return {
			roleId:localStorage.getItem('role_id'),
			active:0,
            list:[],
            fileList:[],
            wx_config:{
                official_account_appid:'',
                secret:''
            },
			callback_config:[],
            coid:"",
            activity_url:'',
			domain:'',
			callbackType:[]
        }
    },
   created(){
       this.coid =  localStorage.getItem('coid')
       this.getData()
   },
   mounted(){
   },
   methods:{
	   question(id){
		   if(id !== 5){	//则是传的type
			    id =  id == 1 ? 6 :(id == 2 ? 7 :8)
		   }
		    this.$refs.docDialog.getDoc(id)
	   },
        questionOpen(msg){
            this.$message(msg)
        },
        getData(){
           this.$api.get('SysConfig/index',null,res=>{
               if(res.code == 200){
                   this.list =  res.data.domain_data
                   this.wx_config =  res.data.wx_config
                   this.callback_config = res.data.callback_config.data??this.callback_config.data
				   this.callbackType =  res.data.callback_config.type_data
				   this.domain  = res.data.domain
               }else{
                    this.$message.error(res.msg)
               }
           })
       },
       //更新
       update(){
           this.$api.post('SysConfig/update',{domain:this.list,wx_config:this.wx_config,callback_config:this.callback_config},res=>{
                if(res.code == 200){
                   this.$message.success(res.msg)
                   this.reload()
               }else{
                   this.$message.error(res.msg)
               }
           })
       },
    //    updateWx(){  //更新公众号配置
    //        this.$api.post('SysConfig/updateWxConfig',this.wx_config,res=>{
    //            if(res.code == 200){
    //                this.$message.success(res.msg)
    //                this.reload()
    //            }else{
    //                this.$message.error(res.msg)
    //            }
    //        })
    //    },
    //    update(){    //更新域名
    //        this.$api.post('SysConfig/update',this.list,res=>{
    //            if(res.code == 200){
    //                this.$message.success(res.msg)
    //                this.reload()
    //            }else{
    //                this.$message.error(res.msg)
    //            }
    //        })
    //    },
    //    updateCallback(){    //更新客户联系配置
    //         this.$api.post('')
    //    }
   }

}
</script>
<style lang="scss">
.page-container{
    background: #ffffff;
}
</style>